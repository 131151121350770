import NavbarContact from "./navbar-contact/navbar-contact.component";
import NavbarNavigations from "./navbar-navigations/navbar-navigations.component";
import { motion } from "framer-motion";
import { opacityAnimation } from "../../utils/animation/animation";
import { useState } from "react";

export default function Navbar() {
	return (
		<motion.div
			// variants={opacityAnimation}
			// initial="hidden"
			// animate="visible"
			className="container flex flex-col gap-1 items-center px-4 relative"
		>
			<NavbarContact />
			<NavbarNavigations />
		</motion.div>
	);
}
