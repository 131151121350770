import Button from "../../global/button/button.component";
import ProductImage from "../../../assets/images/product.png";
import { SuggestionContainer } from "./suggestion-card-larg.style";
import SecondLabel from "../../global/second-label/second-label.component";
import Label from "../../global/label/label.component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Theme from "../../../utils/stylesTheme";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import { useTranslation } from "react-i18next";

export interface IcardData {
	big_text: string;
	link: string;
	main_image: string;
	small_text: string;
	discount: string;
}
export default function SuggestionCardLarg({
	cardData,
}: {
	cardData: IcardData;
}) {
	const { t } = useTranslation();
	const { big_text, link, main_image, small_text, discount } = cardData;
	return (
		<SuggestionContainer
			href={link}
			className="order-1 md:order-none col-span-12 row-span-8 xl:row-span-12  md:col-span-6 xl:col-span-3 h-full flex flex-col justify-center items-center px-4 py-8  shadow gap-2 relative"
		>
			<h3 className="text-xl font-bold " style={{ color: Theme.TextPrimary }}>
				{small_text}
			</h3>
			<p className="text-base text-center font-semibold">{big_text}</p>
			<Label type="red">NEW</Label>
			{+discount && (
				<SecondLabel type="yellow">
					{+discount}% {t("offer")}
				</SecondLabel>
			)}
			<div className="w-64 h-64 flex items-center justify-center">
				<LazyLoadImage
					className="max-h-full max-w-full"
					src={main_image}
					alt=""
				/>
			</div>
			<Button
				type="roundTransparent"
				style={{ borderColor: Theme.TextPrimary }}
			>
				{t("ADD TO CART")}
				<EastOutlinedIcon sx={{ marginLeft: 1 }} />
			</Button>
		</SuggestionContainer>
	);
}
