import { Suspense, lazy } from "react";
import Home from "../../components/home/home.component";

// const Home = lazy(() => import("../../components/home/home.component"));
export default function HomeRoute() {
	return (
		// <Suspense fallback={<h1>Loading...</h1>}>
		<Home />
		// </Suspense>
	);
}
